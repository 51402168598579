import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../images/hero-about-us-beliefs.jpg"
import Layout from "../components/layoutMainCustom"
import PageHeaders from "../components/pageHeaders"
import SEO from "../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const SecondaryButton = styled.button`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Our Beliefs."
    SecondText="Biblical. Relevant. Missional."
  >
    <SEO title="Our Beliefs" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      GOD
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      God is the Creator and Ruler of the universe. He has eternally existed in
      three persons: Father, Son and Holy Spirit. These three are co-equal and
      together constitute one God. <br />
      <br />
      <i>
        Genesis 1:1, 26-27; 3:22; Psalm 90:2; Matthew 28:19, 2 Corinthians
        13:14; 1 Peter 1:2
      </i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      MAN
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Man is made in the spiritual image of God, to be like Him in character. He
      is the supreme object of God’s creation. Although man has tremendous
      potential for good, he is marred by an attitude of disobedience toward God
      called “sin.” This attitude separates man from God.
      <br /> <br />
      <i>Genesis 1:27; Psalm 8:3-6; Isaiah 53:6a; 59:1-2; Romans 3:23</i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      ETERNITY
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Man is made to live forever. He will either live eternally separated from
      God by sin or in union with God through forgiveness and salvation. To be
      eternally separated from God is Hell. To be eternally in union with Him is
      Heaven. Heaven and Hell are real places of eternal existence.
      <br />
      <br />
      <i>John 3:16; Romans 6:23; 1 John 2:25; 5:11-13; Revelation 20:15</i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      JESUS
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Jesus is the Son of God, co-equal and co-eternal with the Father. Jesus
      lived a sinless human life and offered Himself as the perfect sacrifice
      for the sins of all men by dying on a cross. He rose from the dead after
      three days to demonstrate His power over sin and death. He then ascended
      to Heaven and will return again to earth to reign as King of Kings and
      Lord of Lords.
      <br />
      <br />
      <i>
        Isaiah 9:6; Matthew 1:22-23; John 1:1-5; 14:10-30; Acts 1:9-11; Romans
        1:3-4; 1 Timothy 6:14-15; Titus 2:13; Hebrews 4:14-15
      </i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      SALVATION
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Salvation is a gift from God to man. Man can never make up for his sin by
      self-improvement or good works. Only by trusting in Jesus as God’s offer
      of forgiveness can man be saved from sin’s penalty. Eternal life begins
      the moment one receives Jesus into his life by faith.
      <br />
      <br />
      <i>
        John 1:12; 14:6; Roman 5:1; 6:23; Galatians 3:26; Ephesians 2:8-9; Titus
        3:5
      </i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      ETERNAL SECURITY
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Because God gives man eternal life through Jesus Christ, the believer is
      secure in that salvation for eternity. Salvation is maintained by the
      grace and power of God, not by the self-effort of the Christian. It is the
      grace and keeping power of God that gives this security.
      <br />
      <br />
      <i>John 10:29; 2 Timothy 1:12; Hebrews 7:25; 10:10-14; 1 Peter 1:3-5</i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      THE HOLY SPIRIT
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      The Holy Spirit is co-equal and co-eternal with the Father and Son as God.
      He is present in the world to make men aware of their need for Jesus
      Christ. He also lives in every Christian from the moment of salvation. He
      provides the Christian with power for living, understanding of truth and
      guidance in doing what is right. The Christian seeks to live under His
      control daily.
      <br />
      <br />
      <i>
        John 16:7-13; 14:16-17; Acts 1:8; 1 Corinthians 2:12; 3:16; 2
        Corinthians 3:17; Galatians 5:25; Ephesians 1:13; 5:18
      </i>
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      THE BIBLE
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      The Bible is God’s Word to all men. It was written by human authors under
      the supernatural guidance of the Holy Spirit. It is the supreme source of
      truth for Christian beliefs and living. Because it is inspired by God, it
      is truth without any mixture of error.
      <br />
      <br />
      <i>
        Psalm 12:6; 119:105, 160; Proverbs 30:5; 2 Timothy 1:13; 3:16-17; 2
        Peter 1:20-21
      </i>
    </ParagraphText>
  </Layout>
)

export default IndexPage
